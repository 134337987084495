import { useMemo } from 'react'
import { Statistic } from '../types/statistics'

const useStatistic = (statistic: Statistic[]) => {
  const { reviewStatistic, peerRecommendationStatistic, mergedStatistic } = useMemo(
    () => ({
      reviewStatistic: statistic.filter((statisticItem) => statisticItem.tagType === 'review'),
      peerRecommendationStatistic: statistic.filter((statisticItem) => statisticItem.tagType === 'peerRecommendation'),
      mergedStatistic: statistic.filter((s) => s.tagType === 'total')
    }),
    [statistic]
  )

  return { reviewStatistic, peerRecommendationStatistic, mergedStatistic }
}

export default useStatistic

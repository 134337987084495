import { useEffect, useState } from 'react'
import { getReviewDate } from '../utils/date'

export const useDateTimeCorrector = (datetime?: Date | string) => {
  const initialDatetime = getReviewDate(datetime, true)
  const [dateTime, setDateTime] = useState(initialDatetime)

  useEffect(() => {
    const correctedDatetime = getReviewDate(datetime)
    setDateTime(correctedDatetime)
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return dateTime
}

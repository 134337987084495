import React from 'react'
import { Grid, SvgIcon, SxProps } from '@mui/material'

export type RatingProps = {
  starIconId?: string
  emptyStarIconId?: string
  activeStars?: boolean
  isDisabled?: boolean
  max?: number
  value?: number
  sx?: SxProps
  size?: StarIconSize
  readOnly?: boolean
}

type StarIconSize = 'xs' | 'small' | 'medium' | 'large'

enum StarIconDimensions {
  XS = 'xs',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

enum StarIconSizeValue {
  XS = 12,
  SMALL = 16,
  MEDIUM = 24,
  LARGE = 40,
  DEFAULT = 32
}

const getStarIconSize = (size: StarIconSize): number => {
  switch (size) {
    case StarIconDimensions.XS:
      return StarIconSizeValue.XS

    case StarIconDimensions.SMALL:
      return StarIconSizeValue.SMALL

    case StarIconDimensions.MEDIUM:
      return StarIconSizeValue.MEDIUM

    case StarIconDimensions.LARGE:
      return StarIconSizeValue.LARGE

    default:
      return StarIconSizeValue.DEFAULT
  }
}

const Rating = ({
  max = 5,
  sx,
  value = 0,
  size = 'medium',
  starIconId = '#rating-star-icon',
  emptyStarIconId = '#rating-empty-star-icon'
}: RatingProps) => {
  const iconSize = getStarIconSize(size)

  return (
    <Grid
      container
      gap="4px"
      alignItems="center"
      height="100%"
      wrap="nowrap"
      width="auto"
      m="0 2px"
      boxSizing="border-box"
      sx={sx}
    >
      {Array.from({ length: max }).map((_, index) =>
        index >= value ? (
          <SvgIcon
            fontSize="inherit"
            viewBox="0 0 18 17"
            key={`star-rating-item-${index}-empty`}
            sx={{
              width: iconSize,
              height: iconSize
            }}
          >
            <use href={emptyStarIconId} />
          </SvgIcon>
        ) : (
          <SvgIcon
            fontSize="inherit"
            viewBox="0 0 24 23"
            key={`star-rating-item-${index}`}
            sx={{
              width: iconSize,
              height: iconSize
            }}
          >
            <use href={starIconId} />
          </SvgIcon>
        )
      )}
    </Grid>
  )
}

export default Rating

import { Container, styledComponent } from '@hermes/web-components'

const ContentTopContainer = styledComponent(Container, {
  shouldForwardProp: (prop: string) => !['withMargin'].includes(prop)
})<{ withMargin?: boolean }>(({ theme, withMargin = false }) => ({
  paddingLeft: `${theme.spacing(5)} !important`,
  paddingRight: `${theme.spacing(5)} !important`,
  marginTop: withMargin ? 64 : undefined,
  maxWidth: theme.breakpoints.values.xl,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('xl')]: {
    paddingLeft: `${theme.spacing(4)} !important`,
    paddingRight: `${theme.spacing(4)} !important`
  },
  [theme.breakpoints.down('lg')]: {
    boxSizing: 'border-box',
    maxWidth: theme.breakpoints.values.sm,
    marginTop: withMargin ? 44 : undefined,
    paddingLeft: `${theme.spacing(0)} !important`,
    paddingRight: `${theme.spacing(0)} !important`
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: `${theme.spacing(2)} !important`,
    paddingRight: `${theme.spacing(2)} !important`
  },
  [theme.breakpoints.down('sm')]: {
    overflowY: 'hidden'
  }
}))

export default ContentTopContainer

const highlightText = (text: string, value?: string) => {
  if (!value) {
    return text
  }
  const regString = value.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')

  const regExp = new RegExp(regString, 'ig')
  return text.replace(regExp, (str) => `<b class="text-highlight">${str}</b>`)
}

export default highlightText

import dayjs from 'dayjs'

import dayjsutc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(dayjsutc)
dayjs.extend(customParseFormat)

export const getYearsDiff = (date: string | Date): number | null => {
  if (!date) {
    return null
  }
  const todayFullYear = dayjs()
  if (typeof date === 'string') {
    const splitDate = date.split('-')
    const dateFullYear = dayjs(date, Number(splitDate[0]) > Number(splitDate[2]) ? 'MM-DD-YYYY' : 'DD-MM-YYYY')
    return Number(todayFullYear.diff(dateFullYear, 'year').toString())
  }
  const dateFullYear = dayjs(date, 'MM-DD-YYYY')
  return Number(todayFullYear.diff(dateFullYear, 'year').toString())
}

export const getReviewDate = (date?: string | Date, utc?: boolean): string => {
  if (!date) {
    return ''
  }
  return dayjs(date, { utc }).format('DD MMM YYYY')
}

export const isOlderThanNMonth = (nMonth: number, date?: string | Date): boolean => {
  if (!date) {
    return false
  }
  const dateNMonthAgo = dayjs().subtract(nMonth, 'month')
  return dayjs(date).isBefore(dateNMonthAgo)
}

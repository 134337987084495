import { PropsWithChildren } from 'react'
import Head from 'next/head'

interface PageMetaProps {
  title: string
  description: string
  isProfile?: boolean
  profileFirstName?: string
  profileLastName?: string
  profileSlug?: string
  profileGender?: string
  image?: string
  canonicalUrl?: string
  language?: string
  preloadImage?: string
  noindex?: boolean
  hrefLangs?: { rel: string; href: string; hreflang: string }[] | false
}

const PageMeta = (meta: PropsWithChildren<PageMetaProps>) => (
  <Head>
    <title>{meta.title}</title>
    <meta name="twitter:title" content={meta.title} />
    <meta property="og:title" content={meta.title} />

    <meta name="description" content={meta.description} />
    <meta name="twitter:description" content={meta.description} />
    <meta property="og:description" content={meta.description} />
    {meta.isProfile ? (
      <>
        <meta property="og:type" content="profile" />
        {!!meta.profileFirstName && <meta property="profile:first_name" content={meta.profileFirstName} />}
        {!!meta.profileLastName && <meta property="profile:last_name" content={meta.profileLastName} />}
        {!!meta.profileSlug && <meta property="profile:username" content={meta.profileSlug} />}
        {!!meta.profileGender && <meta property="profile:gender" content={meta.profileGender} />}
      </>
    ) : (
      <meta property="og:type" content="website" />
    )}

    <meta property="og:site_name" content="Doctify" />
    {!!meta.preloadImage && <meta property="og:image" content={meta.preloadImage} />}
    {!!meta.canonicalUrl && (
      <>
        <link rel="canonical" href={meta.canonicalUrl} hrefLang={meta.language} />
        <meta property="og:url" content={meta.canonicalUrl} />
      </>
    )}
    {meta?.noindex ? <meta name="robots" content="noindex" /> : null}
    {!!meta.hrefLangs &&
      meta.hrefLangs.map((hrefLang) => <link key={`meta-hreflang-${hrefLang.hreflang}`} {...hrefLang} />)}
    {meta.children}
  </Head>
)

export default PageMeta

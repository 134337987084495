import { PracticeSpecialist } from '../types'

export const practiceSpecialistSort = (specialists: PracticeSpecialist[]): PracticeSpecialist[] => [
  ...specialists.filter((_, index) => index % 2 === 0),
  ...specialists.filter((_, index) => index % 2 !== 0)
]

export const getPracticeSpecialistName = (specialist: PracticeSpecialist, language: string) =>
  [specialist?.title?.[language], specialist?.firstName?.[language], specialist?.lastName?.[language]]
    .filter((n) => !!n)
    .join(' ')

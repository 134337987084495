import { useMemo } from 'react'
import { SpecialistPractice } from '../types'

export const useSpecialistPracticesContacts = (specialistPractices?: SpecialistPractice[]) =>
  useMemo(() => {
    if (!specialistPractices) {
      return {
        practicesWithBookingLink: [],
        practicesWithContactDetails: []
      }
    }

    return {
      practicesWithBookingLink: specialistPractices.filter((practice) => practice.externalBookingLink),
      practicesWithContactDetails: specialistPractices.filter((practice) => {
        const { ContactDetails = [] } = practice

        return (
          ContactDetails.findIndex(
            (cd) => ['public', 'singlePointPublic'].includes(cd.type) && (cd.email || cd.phone)
          ) !== -1
        )
      })
    }
  }, [specialistPractices])

import { styledComponent } from '@hermes/web-components'
import { RIGHT_TO_LEFT } from '../constants/global'

const addRtlProperty = (component: any) =>
  styledComponent(component, { shouldForwardProp: (prop: string) => prop !== 'reflectIfRtl' })<{
    reflectIfRtl?: boolean
    directionLeft?: boolean
  }>(({ reflectIfRtl, directionLeft, theme }) => ({
    transform: (theme.direction === RIGHT_TO_LEFT && reflectIfRtl) || directionLeft ? 'scale(-1)' : undefined
  }))

export default addRtlProperty

import { PracticePlans, ProfileTypes, SpecialistPlans } from '../constants/profile'
import { PracticeProfile, SpecialistProfile } from '../types'

const planIncludes = (profile: SpecialistProfile | PracticeProfile, plans: string[]) =>
  plans.some((plan) => profile.plan?.includes(plan))

export const getIsUnclaimedPlan = (
  profile: SpecialistProfile | PracticeProfile,
  type: ProfileTypes.PRACTICE | ProfileTypes.SPECIALIST
) => {
  if (!profile) {
    return null
  }

  const unclaimedPlans = {
    [ProfileTypes.SPECIALIST]: [SpecialistPlans.UNCLAIMED, SpecialistPlans.NO_PLAN],
    [ProfileTypes.PRACTICE]: [PracticePlans.UNCLAIMED, PracticePlans.NO_PLAN]
  }

  return planIncludes(profile, unclaimedPlans[type] || [])
}

export const getIsProOrExpertProPlan = (
  profile: SpecialistProfile | PracticeProfile,
  type: ProfileTypes.PRACTICE | ProfileTypes.SPECIALIST
) => {
  if (!profile) {
    return null
  }

  const proPlans = {
    [ProfileTypes.SPECIALIST]: [SpecialistPlans.EXPERT, SpecialistPlans.EXPERT_PRO],
    [ProfileTypes.PRACTICE]: [PracticePlans.ENTRY, PracticePlans.UNCLAIMED]
  }

  if (type === ProfileTypes.PRACTICE) {
    return !planIncludes(profile, proPlans[type] || [])
  }

  return planIncludes(profile, proPlans[type] || [])
}

import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const PlayCircleIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 35 35"
    fill="none"
    style={{ ...props.style, borderRadius: '50%', background: '#406AE3' }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.5 16.634C25.1667 17.0189 25.1667 17.9811 24.5 18.366L14.75 23.9952C14.0833 24.3801 13.25 23.899 13.25 23.1292L13.25 11.8708C13.25 11.101 14.0833 10.6199 14.75 11.0048L24.5 16.634Z"
      fill="#EAEEFC"
    />
  </SvgIcon>
)

export { PlayCircleIcon }

import { Box, Grid, styledComponent } from '@hermes/web-components'

export const AvatarContainer = styledComponent(Box)({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '4px',
  'span, img': {
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover',
    zIndex: 1
  }
})

export const AvatarChildrenContainer = styledComponent(Grid)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  color: theme.palette.common.white,
  fontSize: '21px'
}))

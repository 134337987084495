const getImageWithWWW = (href?: string) => {
  if (!href) {
    return ''
  }
  if (['development.', 'staging.', 'www'].some((stage) => href.includes(stage))) {
    return href
  }

  const splitLink = href.split('//')

  const imageWithWWW = `www.${splitLink[1]}`
  splitLink[1] = imageWithWWW

  return splitLink.join('//')
}

export default getImageWithWWW

import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

type CheckboxCircleProps = SvgIconProps & {
  color?: string
}

const CheckboxCircle = ({ ...props }: CheckboxCircleProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#008579"
      fillRule="evenodd"
      d="M19.167 10A9.167 9.167 0 11.833 10a9.167 9.167 0 0118.334 0zM5 10.35l1.175-1.175 2.158 2.15 5.492-5.492L15 7.017l-6.667 6.666L5 10.35z"
      clipRule="evenodd"
    ></path>
  </SvgIcon>
)

export default CheckboxCircle

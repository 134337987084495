import { useMemo } from 'react'
import { SpecialistPractice } from '../types/specialist'

export type WorkingHours = { open: string; close: string }

export type WorkinHoursResult = {
  all: Record<number, WorkingHours[]>
  [key: number]: Record<number, WorkingHours[]> | null
}

export const dayOfWeekMapping = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
}

export default function useSpecialistWorkingHours(practices: SpecialistPractice[]): {
  workingHours: WorkinHoursResult
  hasWorkingHours: boolean
} {
  return useMemo(
    () =>
      practices.reduce(
        (
          prev: { workingHours: WorkinHoursResult; hasWorkingHours: boolean },
          practice
        ): { workingHours: WorkinHoursResult; hasWorkingHours: boolean } => {
          const { workingOpeningHours: workingOpeningHoursPractice, id } = practice
          const workingOpeningHours = workingOpeningHoursPractice || {}
          const res = {
            ...prev,
            workingHours: {
              ...prev.workingHours,
              [id]: workingOpeningHours
            }
          }
          Object.keys(workingOpeningHours).forEach((key) => {
            if (workingOpeningHours[key]?.length) {
              res.workingHours.all[Number(key)] = res.workingHours.all[Number(key)]?.concat(workingOpeningHours[key])
              res.hasWorkingHours = true
            }
          })
          return res
        },
        {
          workingHours: {
            all: { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
          },
          hasWorkingHours: false
        }
      ),
    [practices]
  )
}

import { useContext, useMemo } from 'react'
import { ProfileKeyword } from '../types'
import { AppDataContext } from '../providers/AppData'

export const useKeywordsMapper = (keywords: ProfileKeyword[], isClinic?: boolean) =>
  useMemo(() => {
    const subKeywords = keywords.filter((k) => !k.isTopLevelChild)
    return {
      specialties: (isClinic ? keywords : subKeywords).filter(
        (keyword) => keyword.isTopLevelChild || keyword.parentId === 1
      ),
      subSpecialties: (isClinic ? keywords : subKeywords).filter(
        (keyword) => keyword.keywordType === 'specialty' && !keyword.isTopLevelChild && keyword.parentId !== 1
      ),
      conditionAndTreatments: subKeywords.filter((keyword) => ['condition', 'procedure'].includes(keyword.keywordType))
    }
  }, [isClinic, keywords])

export const useTopLevelKeywords = (keywords: ProfileKeyword[]) => {
  const { language } = useContext(AppDataContext)
  return useMemo(
    () =>
      keywords
        .filter((keyword) => keyword.isTopLevelChild && keyword.keywordType === 'specialty')
        .map((keyword) => keyword.practitioner?.[language] || keyword.name[language])
        .join(', ') || '',
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keywords]
  )
}
